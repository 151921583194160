import React from 'react'
import LanguageContext from "../contexts/LanguageContext";
import { Link as ScrollLink } from 'react-scroll';

class Navigation extends React.Component {

    static contextType = LanguageContext;

    render() {
        const content = this.context;
        return (
            <div style={{
                background: "#091C29"
            }}>
                <div className="flex item-center justify-between w-10/12 mx-auto py-3 text-white font-dosis">
                    <h1 className="text-3xl font-bold">{content.nav.logo}{' '}<span className="w-3 h-3 bg-red-500 inline-block rounded-full"></span></h1>
                    <div>
                        {content.nav.links.map((link, index) => {
                            return (
                                <ScrollLink to={link.to} smooth={true}>
                                    <span key={index} className="text-xl mr-4 cursor-pointer">{link.text}</span>
                                </ScrollLink>
                            )
                        })}
                        <span className="cursor-pointer flag us" onClick={() => this.props.onLanguageChange("en" ) }>EN</span> / <span className="cursor-pointer flag es" onClick={() => this.props.onLanguageChange("es" ) } >ES</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navigation;