import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBuilding, FaSchool } from "react-icons/fa";

import LanguageContext from '../contexts/LanguageContext'
const icon = (icon) => {
    if (icon === "work") {
        return <FaBuilding />
    }
    return <FaSchool />
}

class Experience extends React.Component {
    static contextType = LanguageContext;
    render() {
        const content = this.context;
        return (
            <div className="min-h-screen flex flex-col items-center justify-center font-dosis my-10" id="experience" style={{
                background: "#091C29"
            }}>
                <h1 className="text-5xl font-bold text-white my-10">{content.experience.title}</h1>
                <div className="w-11/12 justify-center mt-10">
                    <VerticalTimeline>
                        {content.experience.timeline.map((exp, index) => {
                            return (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date={exp.date}
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={icon(exp.icon)}

                                >
                                    <h3 className="vertical-timeline-element-title font-bold">{exp.company}</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{exp.place}</h4>
                                    <p className="font-bold">{exp.job} <br />
                                        <span className="font-normal text-gray-50">{exp.description}</span>
                                    </p>
                                </VerticalTimelineElement>
                            )
                        })}
                    </VerticalTimeline>
                </div>
            </div>
        )
    }
}

export default Experience;