import React from 'react'
import LanguageContext from '../contexts/LanguageContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link as ScrollLink } from 'react-scroll';
import Typical from 'react-typical';


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animated: false
        };
    }
    static contextType = LanguageContext;
    componentDidMount() {
        this.setState({ animated: true })


    }
    render() {

        const content = this.context;

        return (
            <div className="min-h-screen flex items-center justify-center" style={{
                background: "#091C29"
            }}>
                <div className="w-10/12 mx-auto flex flex-col md:flex-row-reverse items-center justify-between">
                    <div className="w-full md:w-2/5">
                        <LazyLoadImage
                            src={content.header.img}
                            effect="blur"
                            placeholderSrc={process.env.PUBLIC_URL + '/logo512.png'}
                        />
                    </div>
                    <div className="text-white font-dosis text-center md:text-left">
                        <h2 className={`${this.state.animated ? '' : 'translate-y-10 opaticy-0'} transform transition duration-2000 ease-in-out text-4xl md:text-5xl font-bold`}>
                            {content.header.text[0]}
                            <br />
                            {content.header.text[1]}
                        </h2>
                        <h1 className={`${this.state.animated ? '' : 'translate-y-10 opaticy-0'} transform transition duration-2000 ease-in-out font-bold text-2xl text-gray-500`}>
                            {content.header.text[2]}
                            <Typical
                                steps={content.header.typical}
                                loop={Infinity}
                                className="inline-block"
                            />
                        </h1>
                        <ScrollLink to="stack" smooth={true}>
                            <button className="animate-float bg-indigo-500 px-10 py-3 text-xl uppercase mt-10 rounded-lg">{content.header.btnText}</button>
                        </ScrollLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;
