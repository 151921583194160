import React from "react";
import './App.css';
import Navigation from './components/Navigation';
import Header from './components/Header';
import Stack from './components/Stack';
import Experience from './components/Experience';
import ScrollTopArrow from './components/ScrollTopArrow';
import Project from './components/Project';
import LanguageContext from "./contexts/LanguageContext";
import { EN, ES } from "./contexts/languages";

class App extends React.Component {
  constructor() {
    super();
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }
  state = { language: ES };
  onLanguageChange = language => {
    let newLanguage = {};
    // Change the language based on user action
    switch (language) {
      case "en":
        newLanguage = EN;
        break;
      case "es":
        newLanguage = ES;
        break;
      default:
        newLanguage = ES;
        break;
    }
    this.setState({ language: newLanguage });
  };

  render() {
    return (
      <div>
        <LanguageContext.Provider value={this.state.language}>
          <Navigation onLanguageChange={this.onLanguageChange}/>
          <Header />
          <Stack />
          <Experience />
          <Project />
          <ScrollTopArrow />
        </LanguageContext.Provider>
      </div>
    );
  }
}


export default App;
