import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import LanguageContext from '../contexts/LanguageContext'

class Stack extends React.Component {
    static contextType = LanguageContext;
    render() {
        const content = this.context;
        return (
            <div className="min-h-screen flex flex-col items-center justify-center font-dosis  my-10" id="stack">

                <h1 className="text-5xl font-bold">{content.stack.title}</h1>
                <div className="flex flex-wrap justify-center mt-10">
                    {content.stack.tech.map((tech, index) => {
                        return (
                            <span className={`${index % 2 === 0 ? ' animate-float' : ' animate-refloat'} w-40 h-40 bg-white shadow-2xl m-2 rounded-full flex items-center p-5 `}>
                                <LazyLoadImage src={tech.img} alt={tech.alt} />
                            </span>
                        )
                    })}
                </div>
                <p className="w-11/12 md:max-w-xl text-xl text-center mt-10 ">{content.stack.desc}</p>
            </div>
        )
    }
}

export default Stack;