import React from 'react'
import LanguageContext from '../contexts/LanguageContext'

class Project extends React.Component {
    static contextType = LanguageContext;
    render() {
        const content = this.context;
        return (
            <div className="min-h-screen flex flex-col items-center justify-center font-dosis  my-10" id="project">
                <h1 className="text-5xl font-bold">{content.project.title}</h1>
                <div class="container my-12 mx-auto px-4 md:px-12">
                    <div class="flex flex-wrap -mx-1 lg:-mx-5">
                        {content.project.projects.map((project, index) => {
                            return (
                                <div class="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden my-4 mx-3">
                                    <img class="w-full h-56 object-cover object-center" src={project.img} alt={project.title}></img>
                                    <div class="flex items-center px-6 py-3 bg-gray-900">
                                        <svg class="h-6 w-6 text-white fill-current" viewBox="0 0 512 512">
                                            <path d="M256 48C150 48 64 136.2 64 245.1v153.3c0 36.3 28.6 65.7 64 65.7h64V288h-85.3v-42.9c0-84.7 66.8-153.3 149.3-153.3s149.3 68.5 149.3 153.3V288H320v176h64c35.4 0 64-29.3 64-65.7V245.1C448 136.2 362 48 256 48z" />
                                        </svg>
                                        <h1 class="mx-3 text-white font-semibold text-lg">{project.title}</h1>
                                    </div>
                                    <div class="py-4 px-6">
                                        <p class="py-2 text-lg text-gray-700">{project.desc}</p>
                                        {project.stacks.map((stack, index) => {
                                            return (
                                                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200 uppercase last:mr-0 mr-1px-2 text-sm  mx-2">{stack}</span>
                                            )
                                        })}
                                        <div class="flex items-center mt-4 text-gray-700">

                                            <h1 class="px-2 text-sm">URL: <a href={project.url} target="_blank" rel="noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">{project.url} </a></h1>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
export default Project;
