export const EN = {
    nav: {
        logo: 'AA',
        links: [
            { text: "Stack", to : "stack"},
            { text: "Experience", to: "experience" },
            { text: "Projects", to: "project"}
        ],
    },
    header: {
        img: '/assets/profile.png',
        text: ['Hi!', 'My name is Andrés Arias', "I'm "],
        typical: [
            "Benjamin's Dad.",
            2000,
            "Web developer",
            2000,
            "DevOp Learner",
            2000,
        ],
        btnText: 'Discover More'
    },
    stack: {
        title: 'Stack I use',
        tech: [
            {
              img: process.env.PUBLIC_URL + '/assets/mongo.png',
              alt: 'mongodb',
            },
            {
              img: process.env.PUBLIC_URL + '/logo512.png',
              alt: 'react',
            },
            {
              img: process.env.PUBLIC_URL + '/assets/express.png',
              alt: 'express',
            },
            {
              img: process.env.PUBLIC_URL + '/assets/node.png',
              alt: 'node',
            },
            {
              img: process.env.PUBLIC_URL + '/assets/rails.png',
              alt: 'Ruby on Rails',
            },

          ],
          desc: `I have always sought to learn new things, currently I use the following technologies: NodeJS, React, MongoDB and Ruby on Rails`,
    },
    experience: {
      title: "Experience",
      timeline: [
        {
          contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
          contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
          date:"2020 - present",
          iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
          icon: "work",
          company: "I-Med",
          place: "Santiago, Chile",
          job: "Software Engineer  / Project Manager / Scrum Master",
          description: "Developer with Electronic Medical License, then I became a developer in the Documents team, and then take the leadership of the team, from 2021 I am the Scrum Master of the team"
        },
        {
          contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
          contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
          date:"2018 - 2010",
          iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
          icon: "work",
          company: "Mitocondria",
          place: "Santiago, Chile",
          job: "BackEnd Developer",
          description: "Custom development of Business projects, using Python and Django. Development of modules or API for magento connection and automation"
        },
        {
          contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
          contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
          date:"2018 - 2018",
          iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
          icon: "work",
          company: "IT-Net",
          place: "Santiago, Chile",
          job: "Developer",
          description: "Development of custom application for the management of VTR SetTopBox controlling the service status of each decoder in real time"
        },
        {
          contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
          contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
          date:"2016 - 2018",
          iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
          icon: "work",
          company: "Celeris",
          place: "Santiago, Chile",
          job: "Technology Consultant",
          description: "Backend development for Moodle, make customizable modules, connection to service and personalized reporting depending on customer needs"
        },
        {
          contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
          contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
          date:"2015 - 2016",
          iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
          icon: "work",
          company: "eClass",
          place: "Santiago, Chile",
          job: "Web Developer",
          description: "Backend developer in the IT area, developing internal human resources management platform. After that, I played the role of FrontEnd developer in the Marketing area, making the layout and styling of websites"
        },
        {
          contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
          contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
          date:"2010 - 2014",
          iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
          icon: "school",
          company: "DuocUC",
          place: "Santiago, Chile",
          job: "Student",
          description: "Computer Engineering"
        },
      ]
      
    },
    project: {
      title: 'Projects',
      desc: 'Personal projects to which they can access',
      projects: [
        {
          title: "Portfolio",
          img: process.env.PUBLIC_URL + '/assets/project/portfolio.png',
          desc: "Page created with ReactJS, to show professional information",
          url: "https://aarias.dev",
          stacks: [
            "ReactJS",
          ]
        },
        {
          title: "Api-Country",
          img: process.env.PUBLIC_URL + '/assets/project/countries.png',
          desc: "API-Country is an api in which you can check for information on Countries, by means of their name, region or name, in addition to being able to list regions",
          url: "https://api-country.aarias.dev",
          stacks: [
            "nodejs",
            "MongoDB",
            "Swagger"
          ]
        },
        {
          title: "Disney Plus Clone",
          img: process.env.PUBLIC_URL + '/assets/project/disney.png',
          desc: "Disneys Plus Clone is just a front end and some backend page similar to the original DisneyPlus",
          url: "https://disney-clone.aarias.dev",
          stacks: [
            "Firebase",
            "Redux",
            "ReactJS",
            "styled-components"
          ]
        },
        {
          title: "Slack Clone",
          img: process.env.PUBLIC_URL + '/assets/project/slack.png',
          desc: "Slack Clone is just a FrontEnd similar to the original",
          url: "https://slack-clone.aarias.dev",
          stacks: [
            "Firebase",
            "Redux",
            "ReactJS",
            "styled-components"
          ]
        },
      ]
    }
        
}

export const ES = {
  nav: {
      logo: 'AA',
      links: [
          { text: "Stack", to : "stack"},
          { text: "Experiencia", to: "experience" },
          { text: "Proyectos", to: "project"}
      ],
  },
  header: {
      img: '/assets/profile.png',
      text: ['Hola!', 'Mi nombre es Andrés Arias', "Yo soy "],
      typical: [
          "Papá de Benjamin",
          2000,
          "Web developer",
          2000,
          "Estudiante de DevOp",
          2000,
      ],
      btnText: 'Descubre más'
  },
  stack: {
      title: 'Stack que uso',
      tech: [
          {
            img: process.env.PUBLIC_URL + '/assets/mongo.png',
            alt: 'mongodb',
          },
          {
            img: process.env.PUBLIC_URL + '/logo512.png',
            alt: 'react',
          },
          {
            img: process.env.PUBLIC_URL + '/assets/express.png',
            alt: 'express',
          },
          {
            img: process.env.PUBLIC_URL + '/assets/node.png',
            alt: 'node',
          },
          {
            img: process.env.PUBLIC_URL + '/assets/rails.png',
            alt: 'Ruby on Rails',
          },

        ],
        desc: `Siempre he buscado aprender cosas nuevas, actualmente utilizo las siguientes tecnologías: NodeJS, React, MongoDB y Ruby on Rails`,
  },
  experience: {
    title: "Experiencia",
    timeline: [
      {
        contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
        contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
        date:"2020 - present",
        iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
        icon: "work",
        company: "I-Med",
        place: "Santiago, Chile",
        job: "Ingeniero Desarrollador  / Jefe de Proyectos / Scrum Master",
        description: "Desarrollador en Licencia Médica Eléctronica, luego me converti en desarrollador en el equipo de Documentos, luego tome el puesto de Jefe de Proyectos, a partir de 2021 soy el Scrum Master del Equipo"
      },
      {
        contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
        contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
        date:"2018 - 2010",
        iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
        icon: "work",
        company: "Mitocondria",
        place: "Santiago, Chile",
        job: "BackEnd Developer",
        description: "Desarrollo personalizado de proyectos empresariales, utilizando Python y Django. Desarrollo de módulos o API para conexión y automatización magento"
      },
      {
        contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
        contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
        date:"2018 - 2018",
        iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
        icon: "work",
        company: "IT-Net",
        place: "Santiago, Chile",
        job: "Developer",
        description: "Desarrollo de aplicación personalizada para la gestión de VTR SetTopBox controlando el estado del servicio de cada decodificador en tiempo real"
      },
      {
        contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
        contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
        date:"2016 - 2018",
        iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
        icon: "work",
        company: "Celeris",
        place: "Santiago, Chile",
        job: "Technology Consultant",
        description: "Desarrollo backend para Moodle, creación de módulos personalizables, conexión al servicio e informes personalizados según las necesidades del cliente."
      },
      {
        contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
        contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
        date:"2015 - 2016",
        iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
        icon: "work",
        company: "eClass",
        place: "Santiago, Chile",
        job: "Web Developer",
        description: "Desarrollador backend en el área de TI, desarrollando plataforma de gestión de recursos humanos internos. Después de eso, desempeñé el rol de desarrollador FrontEnd en el área de Marketing, realizando el diseño y estilo de los sitios web."
      },
      {
        contentStyle: "background: 'rgb(33, 150, 243)', color: '#fff'",
        contentArrowStyle:" borderRight: '7px solid  rgb(33, 150, 243)'",
        date:"2010 - 2014",
        iconStyle:" background: 'rgb(33, 150, 243)', color: '#fff'",
        icon: "school",
        company: "DuocUC",
        place: "Santiago, Chile",
        job: "Student",
        description: "Ingeniero en Informática"
      },
    ]
    
  },
  project: {
    title: 'Proyectos',
    desc: 'Proyectos personales',
    projects: [
      {
        title: "Portfolio",
        img: process.env.PUBLIC_URL + '/assets/project/portfolio.png',
        desc: "Página creada con ReactJS, para mostrar información profesional",
        url: "https://aarias.dev",
        stacks: [
          "ReactJS",
        ]
      },
      {
        title: "Api-Country",
        img: process.env.PUBLIC_URL + '/assets/project/countries.png',
        desc: "API-Country es una api en la que puedes consultar información sobre países, mediante su nombre, región o nombre, además de poder listar regiones",
        url: "https://api-country.aarias.dev",
        stacks: [
          "nodejs",
          "MongoDB",
          "Swagger"
        ]
      },
      {
        title: "Disney Plus Clone",
        img: process.env.PUBLIC_URL + '/assets/project/disney.png',
        desc: "Disneys Plus Clone es un proyecto FrontEnd simulando la plataforma original",
        url: "https://disney-clone.aarias.dev",
        stacks: [
          "Firebase",
          "Redux",
          "ReactJS",
          "styled-components"
        ]
      },
      {
        title: "Slack Clone",
        img: process.env.PUBLIC_URL + '/assets/project/slack.png',
        desc: "Slack Clone es un proyecto FrontEnd simulando la plataforma original",
        url: "https://slack-clone.aarias.dev",
        stacks: [
          "Firebase",
          "Redux",
          "ReactJS",
          "styled-components"
        ]
      },
    ]
  }
      
}
